@media print {
  .noprint {
    display: none !important;
  }
  table {
    page-break-inside: avoid;
  }
}

@media not print {
  .noscreen {
    display: none !important;
  }
  table {
    page-break-inside: avoid;
  }
}
